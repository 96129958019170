var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['pl-sidenav', { 'pl-sidenav__collapse': _vm.collapse }]},[_c('div',{staticClass:"pls-org"},[_c('div',{staticClass:"pls-org__img",on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_c('img',{attrs:{"src":_vm.partnerLogo
            ? _vm.partnerLogo
            : require('@/assets/images/layout/partner-layout/sidenav/add-image.svg'),"alt":"default logo","width":"40","height":"40"}})]),(!_vm.collapse)?_c('div',{staticClass:"pls-org__content"},[_c('h6',[_vm._v(_vm._s(_vm.userInfo.partner.org_short_name))]),_c('p',[_vm._v("Studio")])]):_vm._e()]),_c('div',{staticClass:"pls-menu"},[_vm._l((_vm.userMenu),function(m){return _c('div',{key:m.id,class:[
        'pls-menu__item',
        { 'pls-menu__item--active': _vm.isMenuActive(m) }
      ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.navClick(m)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.navClick(m)}}},[_c('img',{attrs:{"src":_vm.isMenuActive(m) ? m.activeImgPath : m.imgPath,"alt":m.title,"id":`${m.title}-${m.id}`}}),(!_vm.collapse)?_c('p',[_vm._v(_vm._s(m.title))]):_vm._e(),(_vm.collapse)?_c('b-tooltip',{ref:"tooltip",refInFor:true,attrs:{"placement":"right","target":`${m.title}-${m.id}`}},[_vm._v(" "+_vm._s(m.title)+" ")]):_vm._e()],1)}),(_vm.userInfo.user.role === 'admin')?_c('div',{staticClass:"pls-menu__advanced"},[_c('label',[_vm._v(_vm._s(_vm.collapse ? "Adv." : "Advanced"))]),_vm._l((_vm.advancedMenu),function(m){return _c('div',{key:m.id,class:[
          'pls-menu__item',
          { 'pls-menu__item--active': _vm.isMenuActive(m) }
        ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.navClick(m)}}},[_c('img',{attrs:{"src":_vm.isMenuActive(m) ? m.activeImgPath : m.imgPath,"alt":m.title,"id":`${m.title}-${m.id}`}}),(!_vm.collapse)?_c('p',[_vm._v(_vm._s(m.title))]):_vm._e(),(_vm.collapse)?_c('b-tooltip',{ref:"tooltip",refInFor:true,attrs:{"placement":"right","target":`${m.title}-${m.id}`}},[_vm._v(" "+_vm._s(m.title)+" ")]):_vm._e()],1)})],2):_vm._e()],2),_c('div',{staticClass:"pls-footer"},[_c('img',{attrs:{"src":require("@/assets/images/layout/partner-layout/sidenav/whoa-logo.svg"),"alt":"whoa-logo","width":_vm.collapse ? 35 : 55,"height":_vm.collapse ? 40 : 64}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }