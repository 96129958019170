<template>
  <div>
    <div class="pl-header">
      <div
        :class="[
          'pl-header__left',
          { 'pl-header__left--edit': isRouteEditUser }
        ]"
      >
        <LxpBreadcrumb :items="breadcrumbItems" v-if="isRouteEditUser" />
        <p v-if="showTitle">{{ title }}</p>
        <img
          src="@/assets/images/partner/course-api-request/help.svg"
          alt="help"
          v-if="$route.name === 'ApiRequest'"
          @click="showModal = true"
        />
      </div>
      <div class="pl-header__right">
        <div class="pl-header__right--user">
          <img
            src="@/assets/images/layout/partner-layout/header/avatar.svg"
            alt="avatar"
          />
          <div class="pl-header__right--name">
            <p>{{ name }}</p>
            <span>{{
              userInfo.user.role === "admin" ? "Super Admin" : "Staff"
            }}</span>
          </div>
          <div
            class="pl-header__right--down pl-header__logout"
            @click="onClick"
          >
            <img
              src="@/assets/images/layout/partner-layout/header/arrow-down.svg"
              alt="arrow-down"
              class="pl-header__logout"
            />
          </div>
        </div>
        <div class="pl-header__right--menu pl-header__logout" v-if="showMenu">
          <div class="pl-header__menu--list pl-header__logout" @click="logout">
            <img
              src="@/assets/images/layout/partner-layout/header/logout.svg"
              alt="logout"
              class="pl-header__logout"
            />
            <span class="pl-header__logout">Logout</span>
          </div>
        </div>
      </div>
    </div>
    <LxpModal
      :show.sync="showModal"
      id="api-support-modal"
      centered
      title="Help"
    >
      <div class="api-support__content">
        <h6>What is API?</h6>
        <p>
          API (Application Programming Interface) is a computing interface that
          defines how software components interact with each other. It is a way
          of programmatically interacting with a separate software component or
          resource and expose functionality for internal or external use and
          testing. API defines what requests can be made, how they will be made
          and hides complexity from developers. API extends systems to partners,
          organizes code, and makes components reusable.
        </p>
        <h6>What is API testing?</h6>
        <p>
          API testing is a set of quality assurance actions that include making
          calls to an API endpoint, getting API responses, and validating API
          status codes, response times, and data against predefined rules. API
          testing is usually performed by a software tool or web service and
          mainly focuses on testing the business logic layer.
        </p>
        <h6>Why is API Testing Important?</h6>
        <p>
          API testing determines whether the API meets expectations for
          functionality, reliability, performance, and security. API testing is
          essential for the entire development, implementation and maintenance
          of APIs. API testing is necessary to accompany the API to make it
          functional and ready for its purpose.
        </p>
        <h6>How do I Test API Online?</h6>
        <p>
          You can test API online by composing and executing various API
          requests right from your browser. To test API online:
        </p>
        <ol>
          <li>
            Enter the URL of the API endpoint and select the appropriate HTTP
            method.
          </li>
          <li>
            In the Content tab, enter the data you want to send to the API
            endpoint.
          </li>
          <li>
            If your API server requires authorization, enter your credentials in
            the Authorization tab.
          </li>
          <li>
            Click Send to submit your API request, check the returned API status
            code, response time, and content.
          </li>
          <li>
            Re-run the API tests by changing the API endpoint URL, HTTP method,
            and request data.
          </li>
        </ol>

        <h6>Test API with Online REST API Client</h6>
        <p>
          ReqBin is the most popular Online REST API testing tool. You can
          quickly and easily test your API by sending API requests to REST API
          endpoints directly from your browser. ReqBin API Tester provides
          millisecond accurate timings for API requests and server responses.
          With the ReqBin load testing tool, you can test the API against
          hundreds of simulated concurrent users from different geographic
          regions.The REST API client works right in your browser. No coding. No
          desktop app. Fully online.
        </p>
        <h6>JSON and XML API Tester</h6>
        <p>
          Easily inspect returned JSON and XML responses. The built-in JSON and
          XML formatters automatically format and validate the returned data and
          highlight any errors in JSON and XML.
        </p>
      </div>
      <template #footer>
        <div class="delete-account__footer">
          <lxp-button variant="outline-secondary1" @click="showModal = false"
            >Close</lxp-button
          >
        </div>
      </template>
    </LxpModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpBreadcrumb, LxpModal, LxpButton } from "didactica";
export default {
  data() {
    return {
      showMenu: false,
      showModal: false
    };
  },
  components: {
    LxpBreadcrumb,
    LxpModal,
    LxpButton
  },
  computed: {
    ...mapGetters(["userAccountInfo", "userInfo", "currentUser"]),
    name() {
      if (this.userAccountInfo) {
        if (this.userAccountInfo.first_name || this.userAccountInfo.last_name) {
          return `${this.userAccountInfo.first_name} ${this.userAccountInfo.last_name}`;
        } else {
          return this.userAccountInfo.username;
        }
      } else {
        return "";
      }
    },
    title() {
      return this.$route.meta.title;
    },
    breadcrumbItems() {
      return [
        {
          text: "User Permissions ",
          active: true,
          to: { name: "User Permissions" }
        },
        {
          // TODO update to email
          text: this.currentUser?.pacing
        }
      ];
    },
    showTitle() {
      let bool;
      this.$route.name !== "Intro" ? (bool = true) : (bool = false);
      return bool;
    },
    isRouteEditUser() {
      return this.$route.name === "EditingUser";
    }
  },
  mounted() {
    document.addEventListener("click", this.onAppClick);
  },
  methods: {
    onClick() {
      this.showMenu = !this.showMenu;
    },
    onAppClick(e) {
      if (!e.target.className.includes("pl-header__logout")) {
        this.showMenu = false;
      }
    },
    logout() {
      this.$keycloak.logout();
    }
  }
};
</script>
<style lang="scss" scoped>
.pl-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
  .pl-header__left {
    display: flex;
    p {
      @include subtitle-large($brand-neutral-900);
      margin-bottom: 0;
    }
    .lxp-breadcrumb {
      .breadcrumb {
        margin-bottom: 4px;
      }
    }
    img {
      margin-left: 10px;
      cursor: pointer;
    }
    &.pl-header__left--edit {
      flex-direction: column;
    }
  }
  .pl-header__right {
    position: relative;
    .pl-header__right--user {
      @include horizontal-space-between;
      padding: $btn-padding-x-sm;
      background: #fff;
      border-radius: $btn-padding-x-default;
      .pl-header__right--name {
        padding: 0 16px 0 12px;
        p {
          @include label-large($brand-neutral-900, 500);
          margin-bottom: 0;
        }
        span {
          color: $brand-neutral-300;
          font-size: 11px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }
      .pl-header__right--down {
        padding: 4px;
        cursor: pointer;
        &:hover {
          background: $brand-secondary1-50;
          border-radius: 100px;
        }
      }
    }
    .pl-header__right--menu {
      position: absolute;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      width: 100%;
      background: $brand-neutral-0;
      border-radius: 8px;
      z-index: 99;
      bottom: -50px;
      .pl-header__menu--list {
        padding: 8px;
        cursor: pointer;
        &:hover {
          background: $brand-secondary1-50;
          border-radius: 8px;
        }
        span {
          @include label-large;
          margin-left: 8px;
        }
      }
    }
  }
}
#api-support-modal {
  h6 {
    @include body-regular(700);
  }
  p {
    @include body-regular(400);
  }
}
</style>
