<template>
  <div class="partner-wrapper h-100">
    <div class="h-100" v-if="showOnboardingLayout">
      <router-view />
    </div>
    <partner-layout v-else>
      <template>
        <router-view></router-view>
      </template>
    </partner-layout>
  </div>
</template>

<script>
import PartnerLayout from "@/components/Layout/PartnerLayout.vue";
import { mapGetters } from "vuex";
export default {
  components: { PartnerLayout },
  computed: {
    ...mapGetters(["userInfo"]),
    showOnboardingLayout() {
      return (
        this.userInfo.show_onboarding && this.userInfo.user.role === "admin"
      );
    }
  }
};
</script>

<style lang="scss">
.partner-wrapper {
  overflow: hidden;
  background: $brand-primary-50;
}
</style>
