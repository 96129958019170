<template>
  <div :class="['pl-sidenav', { 'pl-sidenav__collapse': collapse }]">
    <div class="pls-org">
      <div class="pls-org__img" @click="collapse = !collapse">
        <img
          :src="
            partnerLogo
              ? partnerLogo
              : require('@/assets/images/layout/partner-layout/sidenav/add-image.svg')
          "
          alt="default logo"
          width="40"
          height="40"
        />
      </div>
      <div class="pls-org__content" v-if="!collapse">
        <h6>{{ userInfo.partner.org_short_name }}</h6>
        <p>Studio</p>
      </div>
    </div>
    <div class="pls-menu">
      <div
        :class="[
          'pls-menu__item',
          { 'pls-menu__item--active': isMenuActive(m) }
        ]"
        v-for="m in userMenu"
        :key="m.id"
        @click="navClick(m)"
        tabindex="0"
        @keypress.enter="navClick(m)"
      >
        <img
          :src="isMenuActive(m) ? m.activeImgPath : m.imgPath"
          :alt="m.title"
          :id="`${m.title}-${m.id}`"
        />
        <p v-if="!collapse">{{ m.title }}</p>
        <b-tooltip
          ref="tooltip"
          placement="right"
          :target="`${m.title}-${m.id}`"
          v-if="collapse"
        >
          {{ m.title }}
        </b-tooltip>
      </div>
      <div class="pls-menu__advanced" v-if="userInfo.user.role === 'admin'">
        <label>{{ collapse ? "Adv." : "Advanced" }}</label>
        <div
          :class="[
            'pls-menu__item',
            { 'pls-menu__item--active': isMenuActive(m) }
          ]"
          v-for="m in advancedMenu"
          :key="m.id"
          @click="navClick(m)"
          tabindex="0"
        >
          <img
            :src="isMenuActive(m) ? m.activeImgPath : m.imgPath"
            :alt="m.title"
            :id="`${m.title}-${m.id}`"
          />
          <p v-if="!collapse">{{ m.title }}</p>
          <b-tooltip
            ref="tooltip"
            placement="right"
            :target="`${m.title}-${m.id}`"
            v-if="collapse"
          >
            {{ m.title }}
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="pls-footer">
      <img
        src="@/assets/images/layout/partner-layout/sidenav/whoa-logo.svg"
        alt="whoa-logo"
        :width="collapse ? 35 : 55"
        :height="collapse ? 40 : 64"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      collapse: false
    };
  },
  watch: {
    collapse(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("collapse", newValue);
      }
    }
  },
  created() {
    this.$store.dispatch("getPartnerLogo");
  },
  computed: {
    ...mapGetters(["partnerLogo", "userInfo"]),
    userMenu() {
      let arr = this.menu.filter(m => !m.isAdvanced),
        capabilities = this.userInfo.partner.capabilities.find(
          c => c.code_name === "learning_spaces"
        );
      if (!capabilities.is_enabled) {
        let learningSpaceIndex = arr.findIndex(a => a.id === "learningSpace");
        arr.splice(learningSpaceIndex, 1);
      }
      return arr;
    },
    advancedMenu() {
      return this.menu.filter(m => m.isAdvanced);
    }
  },
  methods: {
    navClick(n) {
      this.$router.push({ name: n.routeName }).catch(() => {});
    },
    isMenuActive(m) {
      let routeName = this.$route.name.toLowerCase(),
        id = m.id.toLowerCase();
      return (
        routeName.includes(id) || m.activeRouteList.includes(this.$route.name)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.pl-sidenav {
  background: $brand-neutral-0;
  border-radius: 8px;
  padding: 8px;
  width: 180px;
  height: 95%;
  position: fixed;
  transition: width 0.5s ease-in-out;
  &.pl-sidenav__collapse {
    width: 56px;
  }
  .pls-org {
    display: flex;
    align-items: center;
    padding: 8px 0;
    .pls-org__img {
      @include flex-horizontal-center;
      cursor: pointer;
      img {
        object-fit: cover;
        border-radius: 100%;
      }
    }
    .pls-org__content {
      padding: 0 8px;
      h6 {
        @include subtitle-regular;
        color: $brand-neutral-900;
        margin-bottom: 0;
      }
      p {
        @include label-large($brand-neutral-900);
        margin-bottom: 0;
      }
    }
  }
  .pls-menu {
    padding: 32px 0;
    .pls-menu__item {
      display: flex;
      align-items: center;
      padding: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background: $brand-secondary1-50;
      }
      p {
        padding-left: 8px;
        margin-bottom: 0;
        @include label-large($brand-neutral-700, 500);
      }
      &.pls-menu__item--active {
        background: $brand-secondary1-50;
        mix-blend-mode: multiply;
        p {
          color: $brand-secondary1-400;
        }
      }
    }
  }
  .pls-menu__advanced {
    margin-top: 16px;
    label {
      @include label-large($brand-neutral-300);
      margin-bottom: 8px;
    }
  }
  .pls-footer {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
}
</style>
