<template>
  <div class="partner-layout__wrapper">
    <div class="plw__sidenav h-100">
      <SideNav :menu="menu" @collapse="toggleCollapse" />
    </div>
    <div :class="['plw__main', { 'menu-collapse': isSideMenuCollapsed }]">
      <Header />
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Header from "./PartnerLayout/Header.vue";
import SideNav from "./PartnerLayout/SideNav.vue";

export default {
  components: { Header, SideNav },
  data() {
    return {
      isSideMenuCollapsed: false
    };
  },
  computed: {
    headerTitle() {
      return this.$route.name;
    },
    menu() {
      let arr = [
        {
          id: "home",
          title: "Home",
          routeName: "Home",
          activeRouteList: ["Home"],
          imgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/home.svg"),
          activeImgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/home-active.svg"),
          isAdvanced: false
        },
        {
          id: "courses",
          title: "Courses",
          routeName: "CourseList",
          activeRouteList: ["CourseList", "ManualCourseListing"],
          imgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/modules.svg"),
          activeImgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/modules-active.svg"),
          isAdvanced: false
        },
        {
          id: "learningSpace",
          title: "Learning Spaces",
          routeName: "LearningSpace",
          activeRouteList: ["LearningSpace"],
          imgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/learning-spaces.svg"),
          activeImgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/learning-spaces-active.svg"),
          isAdvanced: false
        },
        {
          id: "settings",
          title: "Settings",
          routeName: "Settings",
          activeRouteList: ["Settings"],
          imgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/settings.svg"),
          activeImgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/settings-active.svg")
        },
        {
          id: "user",
          title: "User Permissions",
          routeName: "UserList",
          activeRouteList: ["UserList"],
          imgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/user-permissions.svg"),
          activeImgPath: require("@/assets/images/layout/partner-layout/sidenav/menu/user-permissions-active.svg"),
          isAdvanced: true
        }
      ];

      return arr;
    }
  },
  created() {
    this.$store.dispatch("getUserAccountInfo");
  },
  methods: {
    toggleCollapse(val) {
      this.isSideMenuCollapsed = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.partner-layout__wrapper {
  padding: 16px 0 16px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  .plw__main {
    padding: 0 32px 0 48px;
    width: 100%;
    overflow: hidden auto;
    margin-left: 180px;
    transition: margin-left 0.5s ease-in-out;
    &.menu-collapse {
      margin-left: 56px;
    }
  }
}
</style>
